<template>
  <div
    class="flex flex-col items-center md:tracking-[10px] text-white  min-h-screen bg-cover bg-center"
    :style="{'background-image': `url(${bgImg})`}"
  >
    <Logo class="w-75 mt-10 md:mt-32" />

    <div class="md:text-4xl grid text-center gap-2 mt-16">
      <div class="flex items-center">
        <span class="text-primary">{{ foreverDomain }}는</span>
        <div>더쉽고 편리하게 접속하실 수 있는</div>
      </div>
      <div>평생 도메인을 회원님들께 제공해드리고 있습니다.</div>
    </div>

    <div class="flex flex-col gap-4 md:gap-8 md:text-4xl mt-13 ">
      <div
        v-for="domain in domains"
        class="w-60 md:w-170 border-2 p-3 md:p-5 flex justify-center hover:underline"
      >
        <a
          :href="`http://${domain}`"
          target="_blank"
        >{{ domain }}</a>
      </div>
    </div>

    <div class="my-12  grid gap-2">
      <div class="flex justify-center items-center gap-2">
        <span class="text-secondary md:text-4xl">{{ foreverDomain }}</span>
        <span class="md:text-4xl">만 기억하시면 주소를 잊으셔도</span>
      </div>
      <div>
        <span class="text-primary md:text-4xl">{{ foreverDomain }}는</span>
        <span class="md:text-4xl">언제나 쉽고 빠르게 접속하실 수 있습니다.</span>
      </div>
    </div>
  </div>
</template>
<script setup>
import bgImg from '@/assets/domain/bg.png'
import Logo from "@/components/Logo.vue"
import axios from 'axios'
import {onMounted, ref} from "vue";

const foreverDomain = import.meta.env.VITE_FOREVER_DOMAIN
const domains = ref([])
onMounted(async () => {
  const {data} = await axios.get('api/domains')
  domains.value = data.data.data.map(o => o.domain)
})
</script>
